import { renderForCriticalMoment, renderForGeoLocation } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets, haBarDetailedByMonthMapper, haLeafLineDetailedByGeoMapper, haLineDetailedByGeoMapper, haLineDetailedByMonthMapper } from './commonWidgetsHa'


export const quarterColorsOld = [
  ['#F4F8F4', '#FFD9B7', '#f76d6d'],
  ['#DCF1DE', '#F4F8F4', '#FFD9B7'],
  ['#BBE4BF', '#DCF1DE', '#F4F8F4'],
]
export const quarterColors = [
  ['#F57B51', '#FAB68A', '#B5EB85'],
  ['#FAB68A', '#F8DDD3', '#C6EBA4'],
  ['#F8DDD3', '#C6EBA4', '#CBECC3'],
]

const allWidgets = [
  /**
   * NPS
   */
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haLineDetailedByMonthAndGeo',
    title: 'NPS General por mes',
    position: [0, 0],
    size: [4, 4],
    indicator: 'NPS_GENERAL',
    // @ts-ignore
    mapper: haLineDetailedByMonthMapper,
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haBarDetailedByMonthAndGeo',
    title: 'NPS General por región',
    position: [0, 4],
    size: [4, 3],
    // @ts-ignore
    mapper: haBarDetailedByMonthMapper,
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    indicator: 'NPS_GENERAL',
    //render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29]),
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haLeafLineDetailedByMonthAndGeo',
    title: 'NPS General por mes',
    position: [0, 0],
    size: [4, 4],
    extraConfigs: {
      backgroundColor: 'transparent',
      theme: 'dark',
    },
    // @ts-ignore
    mapper: haLeafLineDetailedByGeoMapper,
    indicator: 'NPS_GENERAL',
    key: 'nps-line-detailed-leafs',
  }),
  /**
   * Satisfacción
   */
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haLineDetailedByMonthAndGeo',
    title: 'Satisfacción General por mes',
    position: [0, 7],
    size: [4, 4],
    // @ts-ignore
    mapper: haLineDetailedByGeoMapper,
    indicator: 'CSAT_GENERAL',
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haBarDetailedByMonthAndGeo',
    title: 'Satisfacción General por región',
    position: [0, 11],
    size: [4, 3],
    // @ts-ignore
    mapper: haBarDetailedByMonthMapper,
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    indicator: 'CSAT_GENERAL',
    //render: (filters: FiltersUI) => renderForCriticalMoment(filters, [29]),
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haLeafLineDetailedByMonthAndGeo',
    title: 'Satisfacción General por mes',
    position: [0, 4],
    size: [4, 4],
    extraConfigs: {
      backgroundColor: '#3a1b52',
      theme: 'dark',
    },
    // @ts-ignore
    mapper: haLeafLineDetailedByGeoMapper,
    indicator: 'CSAT_GENERAL',
    key: 'csat-line-detailed-leafs',
  }),
  {
    title: 'Análisis de datos',
    type: 'label',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 14] : [0, 8]
    },
    size: [4, 1]
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    title: 'Ranking NPS mejores hospitales',
    name: 'barByGeo',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 15] : [0, 9]
    },
    size: [4, 2],
    indicator: 'NPS_GENERAL',
    extraIndicatorConfigs: {
      sort: { value: 'desc' },
      limit: 6
    },
    extraConfigs: {
      wrapTexts: true
    },
    extras: {
      groupByLevel: 2
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    title: 'Ranking NPS hospitales a mejorar',
    name: 'barByGeo',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 17] : [0, 11]
    },
    size: [4, 2],
    indicator: 'NPS_GENERAL',
    extraIndicatorConfigs: {
      sort: { sortBy: 'value', value: 'asc' },
      limit: 6
    },
    extraConfigs: {
      wrapTexts: true
    },
    extras: {
      groupByLevel: 2
    },
  }),
  {
    title: 'Matriz de Priorización',
    type: 'label',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 19] : [0, 13]
    },
    size: [4, 1],
    criticalMomentInclude: [10301]
    //config: { color: '#B7AADC' },
  },
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'quarterTrend',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 20] : [0, 14]
    },
    extraConfigs: {
      colors: quarterColors
    },
    size: [4, 3],
  }),
    getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'haSentimentPieNPS',
    title: 'Análisis de sentimientos',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 23] : [0, 17]
    },
    size: [2, 2],
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'wordCountWithLinks',
    title: 'Nube de Palabras / Frecuencia en comentarios',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [2, 23] : [2, 17]
    },
    size: [2, 2],
    mapper: (data, extra) => {
    return data.map((item: any) => ({
        ...item,
        tags: [...item.tags].filter((tag: any) => tag.group !== 'DEFAULT').sort((a: any, b: any) => b.count - a.count).slice(0,10)
      }))
    },
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'textAnalysis',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 25] : [0, 19]
    },
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),
  getCommonWidget({
    commonWidgets: commonWidgets,
    name: 'diagramaIshikawa',
    title: 'Diagrama Ishikawa',
    position: (filters: FiltersUI) => {
      const isGeoRoot = renderForGeoLocation(filters, [0])
      return isGeoRoot ? [0, 28] : [0, 22]
    },
    size: [4, 3],
    indicator: 'SENTIMENT'
  }),

]

export default {
  title: 'Default Dashboard',
  description: 'Dashboard de perfil Default',
  widgets: (filters: FiltersUI) => {
    const isCmRoot = renderForCriticalMoment(filters, [29])
    const isGeoRoot = renderForGeoLocation(filters, [0])

    return allWidgets.filter((wdg: any) => {
      return (isCmRoot && isGeoRoot && wdg.widgetName === 'haBarDetailedByMonthAndGeo')
        || (isCmRoot && isGeoRoot && wdg.widgetName === 'haLineDetailedByMonthAndGeo')
        || (isCmRoot && !isGeoRoot && wdg.widgetName === 'haLeafLineDetailedByMonthAndGeo')
        || (wdg.widgetName !== 'haBarDetailedByMonthAndGeo'
          && wdg.widgetName !== 'haLineDetailedByMonthAndGeo'
          && wdg.widgetName !== 'haLeafLineDetailedByMonthAndGeo')
    })
  },
} as DasboardConfig
