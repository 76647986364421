
import logoSuzukiSmall from '@assets/logos/suzuki-logo-small.png'
import logoSuzuki from '@assets/logos/suzuki-logo.png'
import { ClientConfigInfo, FILTER_TYPES, METADADATA_LOAD, SurveyResponsesTab } from './configCommons'
import { DASHBOARDS, DASHBOARDS_CONFIGS } from '../dashboard/dashboardCommons'
import { getFilterCriticalMomentDefault, getFilterCriticalMoments } from '../dashboard/dashHelper'
import { omoikaneSuzuki } from '../omoikane/omoikaneSuzuki'
import deafultHelpDefinition from '../help/defaultHelp'

export const suzukiConfigs: ClientConfigInfo = {
    vocBackendUrl: 'https://suzuki-backend.limetropy.com/api/',
    lcmBackendUrl: '',
    //vocBackendUrl: 'http://localhost:9000/api/',
    publicUrl: undefined,
    logoSmall: logoSuzukiSmall,
    logo: logoSuzuki,
    filtersFromDateSubstract: 2,
    filtersToDateSubstract: 1,
    decimals: undefined,
    supportsInternationalization: false,
    filters: [FILTER_TYPES.DATES, FILTER_TYPES.GEOS, FILTER_TYPES.CRITICAL_MOMENTS],
    menuDashboards: [DASHBOARDS.DEFAULT],
    msExcelReportExt: 'xls',
    geosRootId: 1,
    logicalsRootId: 0,
    criticalMomentsRootId: 1,
    criticalMomentFilterType: 'select',
    actionPlans: {
        geoLeafLevel: '',
        useWorstTags: true
    },
    sentimentCommentsIndicatorName: 'SENTIMENT_COMMENTS',
    omoikane: omoikaneSuzuki,
    getFilterCriticalMoments: getFilterCriticalMoments,
    getFilterCriticalMomentDefault: getFilterCriticalMomentDefault,
    useBackDashboards: false,
    metadata: [
        METADADATA_LOAD.GEOS,
        METADADATA_LOAD.CRITICAL_MOMENTS,
        METADADATA_LOAD.DYNAMIC_REPORT_FIELDS
    ],
    dashboards: {
        ...DASHBOARDS_CONFIGS.suzuki,
        default: DASHBOARDS_CONFIGS.suzuki[DASHBOARDS.DEFAULT]
    },
    staticDashboards: {},
    surveyResponses: {
        url: 'survey-responses',
        type: 'get',
        tableColumns: undefined,
        tabs: [SurveyResponsesTab.ANSWERED, SurveyResponsesTab.NOT_ANSWERED],
    },
    alertDetailFields: [],
    help: deafultHelpDefinition
}
