import React, { useRef, useState } from 'react'
import { Unsubscribe } from 'redux'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route } from 'react-router-dom'
import { History } from 'history'
import Cookies from 'js-cookie'
import { PersistGate } from 'redux-persist/integration/react'
import config from '@config'
import usePrevious from '@hooks/usePrevious'
import { MenuStateContextProvider } from '@hooks/useMenuState'
import { ScrollerProvider } from '@hooks/useScroller'
import modules from '@modules'
import BlockLoader from '@components/BlockLoader'
import { getSsoConfig, isSsoActive } from '@utils/sso'
import dayjs from 'dayjs'
import { useLoadScript } from '@react-google-maps/api'
import useClientTranslation from 'config/clients/useClientTranslation'

type AppProps = {
  store: Store
  routes: Route[] | JSX.Element
  history: History
}

const App = ({ store, routes, history }: AppProps): JSX.Element => {
  const { translationReady } = useClientTranslation()
  const [updatedStore, setUpdatedStore] = useState<boolean>(false)
  const [rehydrated, setRehydrated] = useState<boolean>(false)
  let syncingWithServer = usePrevious<boolean>(false)
  const storeSubscriber = useRef<Unsubscribe>()

  // Triggers the rehydratation or the purge of the
  // persisted data depending on the config
  React.useEffect(() => {
    const { persistConfig } = config

    if (localStorage.version !== persistConfig.version.toString()) {
      console.info('[[APP]]: PERSIST PURGE - Reducer Version Change Detected')
      localStorage.setItem('version', persistConfig.version.toString())
      store.persistor.purge().then(() => {
        store.persistor.persist()
      })
    } else {
      // Run rehydration of persisted stores with config options required for immutable transforms
      console.info('[[APP]]: PERSIST REHYDRATED')
      store.persistor.persist()
    }
  }, [store.persistor])

  // If the store was rehdydrated and the user it's logged in updates
  // the local info with the server
  React.useEffect(() => {
    //console.log('rehydrated!!!!!!!!')
    if (rehydrated && !updatedStore && !storeSubscriber.current) {
      let token = undefined
      const sso = getSsoConfig(window.location.origin)
      
      console.log(`DEBUG getSsoConfig(${window.location.origin}) = ${JSON.stringify(sso)}`)

      token = sso ? Cookies.get(sso.cookieName) : undefined
      
      console.log(`DEBUG sso token = ${token}`)

      if (token) {
          store.dispatch(
            modules.actions.auth.setToken({
              token: token,
            })
          )
      } else {
        const { auth } = store.getState()
        token = auth.token
      }

      if (token) {
        storeSubscriber.current = store.subscribe(() => {
          const { account, appData } = store.getState()
          if (
            account.loadingInfo !== syncingWithServer &&
            !account.loadingInfo
          ) {
            syncingWithServer = account.loadingInfo
            setUpdatedStore(true)

            store.dispatch(
              modules.actions.appData.getSurveyCount({
                filters: {
                  ...appData.filters,
                  fromDate: dayjs().subtract(90, 'days').format('YYYY-MM-DD'),
                  toDate: dayjs().format('YYYY-MM-DD'),
                },
              })
            )
            store.dispatch(
              modules.actions.appData.getAlertsCount({
                filters: {
                  ...appData.filters,
                  fromDate: dayjs().subtract(90, 'days').format('YYYY-MM-DD'),
                  toDate: dayjs().format('YYYY-MM-DD'),
                },
                range: 90,
              })
            )
          } else {
            syncingWithServer = account.loadingInfo
          }
        })

        //console.log('BUSCANDO INFOOOOOOOO')
        store.dispatch(modules.actions.account.getInfo())
      } else {
        setUpdatedStore(true)
      }
    }
  }, [rehydrated])

  // Remove the custom store subscriber
  React.useEffect(() => {
    if (updatedStore && !!storeSubscriber.current) {
      storeSubscriber.current()
    }
  }, [updatedStore, storeSubscriber])

  const beforeLift = () => {
    setRehydrated(true)
  }

  console.info('Loading google app with key ' + process.env.REACT_APP_GOOGLE_API_KEY)

  useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY
      ? process.env.REACT_APP_GOOGLE_API_KEY
      : '',
  })

  return (
    <Provider store={store}>
      <MenuStateContextProvider>
        <ScrollerProvider>
          <PersistGate
            persistor={store.persistor}
            loading={<BlockLoader />}
            onBeforeLift={beforeLift}
          >
            {!translationReady || !updatedStore ? (
              <BlockLoader />
            ) : (
              <ConnectedRouter history={history}>{routes}</ConnectedRouter>
            )}
          </PersistGate>
        </ScrollerProvider>
      </MenuStateContextProvider>
    </Provider>
  )
}

export default App
