import { renderForCriticalMoment } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { commonWidgets } from './commonWidgetsHa'

export default {
  title: 'Social Listening',
  description: 'Social Listening',
  backgroundClassName: (filters) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      key: 'text-analysis-sl',
      position: [0, 0],
      size: [4, 4],
      indicator: 'SENTIMENT',
      extras: {
        status: 'EXTERNAL_SOURCE',
        criticalMoments: undefined
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagBest',
      title: 'Sentimiento positivo con más frecuencia',
      position: [0, 4],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_DOWN',
        topSize: 3,
        status: 'EXTERNAL_SOURCE',
        criticalMoments: undefined
      },
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'rankingByTagWorst',
      title: 'Sentimiento negativo con más frecuencia',
      position: [2, 4],
      size: [2, 1],
      indicator: 'SENTIMENT',
      extras: {
        trunc: 'HALF_UP',
        bottomSize: 3,
        status: 'EXTERNAL_SOURCE',
        criticalMoments: undefined
      },
    }),
    {
      title: 'Indicadores generales',
      helpLink: '--',
      position: [0, 5],
      size: [4, 2],
      config: {
        id: 'general-indicators',
        type: 'dataTable',
        color: ['#CCC'],
        enableArcLabels: true,
        valueFormat: ' >-.0%',
        groupName: 'Campaña',
        mapper: (data: any): any => {
          return data.map((item: any) => {
            return [
              'Sentimiento Positivo',
              'Sentimiento Negativo',
              'Promedio Likes'
            ].map((ind: string) => ({
              groupName: ind,
              'group_0': ind,
              'group_1': item['Hospital'],
              value: item[ind]
            }))
          }).flat(1)
        },
        indicators: [
          {
            indicator: 'NPS_GENERAL',
            url: 'reports/indicators-social',
            label: 'group',
            value: 'value',
            singleton: true,
            keyExtract: [
              'Hospital',
              'Sentimiento Positivo',
              'Sentimiento Negativo',
              'Promedio Likes'
            ],
            extras: {
              resultType: 'JSON',
              status: 'EXTERNAL_SOURCE'
            }
          }
        ]
      }
    }, ,
  ],
} as DasboardConfig
