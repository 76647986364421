import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

const CJ_ORDER = {
    'Compra': 1,
    'Abordaje': 2,
    'Autobús-Comodidad': 3,
    'Autobús-Funcionalidad': 4,
    'Autobús-Limpieza': 5,
    'Conductor': 6,
    'Arribo': 7
}

export const omoikaneIamsa: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP
    ],
    monthdsCoverage: 2,
    indicators: [
        {
            indicator: 'EXPERIENCIA_MOMENTO_CRITICO',
            key: 'customer-journey',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'SATISFACCION_GENERAL',
            key: 'satisfaction-general',
            keyExtract: [],
            grouped: 'geoLocation',
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'NPS',
            key: 'nps',
            keyExtract: [],
            //grouped: 'geoLocation',
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'EXPERIENCIA_MARCA',
            key: 'experiencia-marca',
            keyExtract: [],
            //grouped: 'logicalLocation',
            extras: {
                omoikane: true
            }
        },
        {
            indicator: 'sentiment',
            key: 'sentiment-general',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true
            }
        },
    ],
    sentiment: {
        indicators: {
            default: 'sentiment-general'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            default: [
                { order: 1, name: 'NPS', key: 'nps', widgetType: 'pie' },
                { order: 2, name: 'Experiencia marca', key: 'experiencia-marca', widgetType: 'gauge' },
            ]
        }
    },
    clusters: {},
    customerJourney: {
        default: (data: any) => {
            /*const nps = data['nps']?.data[0].value
            const em = data['experiencia-marca']?.data[0].value*/

            const cjData = data['customer-journey']?.data
            .map((item: any, idx: number) => {
                return {
                    key: idx,
                    number: (idx + 1).toString(),
                    weight: CJ_ORDER[item.group],
                    title: item.group.replaceAll('-', ' '),
                    items: [
                        { order: 1, name: '', value: item.value }/*,
                    { order: 1, name: '', value: item.value }*/
                    ]
                }
            })// @ts-ignore
            .asMutable()
            .sort((item1: any, item2: any) => item1.weight - item2.weight )

            return cjData
        }
    }
}
