import { answeredMapper, invalidMapper } from '../dashHelper'
import { getCommonWidget } from '../dashWidgetHelper'
import { getLineDetailedRadar } from '../widgetsLibrary/widgetsLine'
import { commonWidgets, getCmID } from './commonWidgetsJac'

export default {
  title: 'CXO Venta/Postventa Dashboard',
  description: 'Dashboard de CXO Venta/Postventa',
  backgroundClassName: (filters: any) => 'epsilon-hero-bg',
  widgets: (filters: FiltersUI) => [
    /* getLineDetailedRadar({
      mainIndicator: 'CSAT_GENERAL',
      radialIndicators: [
        'CSATVN',
        'entregavn',
        'survey-response-counters',
        'entregapv',
        'recomendacionpv'
      ],
      radialType: 'byIndicator',
      position: [0, 0],
      size: [4, 4],
      title: 'widgets.snByGeo',
      extraConfigs: {
        backgroundColor: 'transparent',
        theme: 'dark',
        showRadialChart: true,
      },
      //criticalMomentInclude: [1],
      groupByLevel: 1,
      useNormalizedRadial: false,
      //normalizeBottom: 0,
      //normalizeTop: 100,
      indicatorsNames: {
        'entregavn': 'Satisfacción Venta',
        'CSATVN': 'NPS Venta',
        'survey-response-counters': '% de Respuestas',
        'entregapv': 'Satisfacción Servicio',
        'recomendacionpv': 'NPS Servicio'
      },
    }), */
    {
      title: 'Encuestas',
      type: 'label',
      position: [0, 0],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    {
      title: 'Alertas',
      type: 'label',
      position: [2, 0],
      size: [2, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByMonth',
      subTitle: 'widgets.surveyCountMonth',
      position: [0, 1],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'surveyCountTrendByWeek',
      subTitle: 'widgets.surveyCountWeek',
      position: [1, 1],
      size: [1, 2],
      indicator: 'SURVEY_COUNT',
      criticalMomentInclude: [getCmID('venta')],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByMonth',
      subTitle: 'widgets.alertsCountMonth',
      position: [2, 1],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: getCmID('venta'),
      extraConfigs: {
        useCurrent: 0,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'alertsMultipleTrendByWeek',
      subTitle: 'widgets.alertsCountWeek',
      position: [3, 1],
      size: [1, 2],
      indicator: 'ALERT_FIRST_RESPONSE_TIME',
      criticalMomentId: getCmID('venta'),
      extraConfigs: {
        useCurrent: 0,
      }
    }),
    {
      title: 'labels.venta',
      type: 'label',
      position: [0, 3],
      size: [4, 1],
      config: { color: '#B7AADC' },
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsVenta',
      position: [0, 4],
      size: [2, 2],
      indicator: 'CSATVN',
      criticalMomentInclude: [getCmID('venta')],
      extraConfigs: {
        useCurrent: 0,
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosVenta',
      position: [2, 4],
      size: [1, 2],
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasVenta',
      indicator: 'venta-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [3, 4],
      size: [1, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyVenta',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      indicator: 'VENTA_CUSTOMER_JOURNEY',
      position: [0, 6],
      extraConfigs: {
        chartRadius: '70%',
        groupNameFormat: 'description'
      },
      //position: [0, 4],
      size: [4, 2],
    }),
    {
      title: 'labels.postventa',
      type: 'label',
      position: [0, 8],
      size: [4, 1],
    },
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleTrendByMonth',
      title: 'widgets.npsPostventa',
      position: [0, 9],
      size: [2, 2],
      indicator: 'recomendacionpv',
      extraConfigs: {
        useCurrent: 0
      }
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.calidadDatosPostventa',
      position: [2, 9],
      size: [1, 2],
      indicator: 'calidad-gauge',
      url: 'survey-responses/values',
      mapper: invalidMapper
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'gaugeGenericByStatus',
      title: 'widgets.porcRespuestasPostventa',
      indicator: 'postventa-respuestas-gauge',
      url: 'survey-responses/values',
      mapper: answeredMapper,
      position: [3, 9],
      size: [1, 2],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'multipleGaugeByQuestion',
      title: 'widgets.customerJourneyPostventa',
      mapper: (data: any, extra: any) => {
        return data.map((item: any) => {
          return {
            ...item,
            label: 'widgets.indicatorId.' + item.groupId
          }
        })
      },
      extraConfigs: {
        groupNameFormat: 'description'
      },
      indicator: 'POSTVENTA_CUSTOMER_JOURNEY',
      position: [0, 11],
      size: [4, 2]
    }),
    //==== sentiment analysis
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'textAnalysis',
      title: "widgets.sentimentAnalysis",
      indicator: 'SENTIMENT',
      position: [0, 13],
      size: [4, 3],
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'diagramaIshikawa',
      title: 'widgets.ishikawa',
      position: [2, 16],
      size: [4, 2],
      indicator: 'SENTIMENT'
    }),
    getCommonWidget({
      commonWidgets: commonWidgets,
      name: 'wordCountWithLinks',
      title: 'Nube de Palabras / Frecuencia en comentarios',
      position: [0, 18],
      size: [4, 3],
      criticalMomentInclude: [getCmID('venta'), getCmID('servicio')],
      extraConfigs: {
        symbolSizeMultiplier: 1.5,
        forceGravity: 0.5
      }
    }),
  ],
} as DasboardConfig
