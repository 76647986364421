import { OmoikaneConfig, OmoikaneTab } from "./omoikaneCommons";

export const omoikaneJac: OmoikaneConfig = {
    decimals: 2,
    enabledTabs: [
        OmoikaneTab.CUSTOMER_JORUNEY,
        OmoikaneTab.CX_RAY,
        OmoikaneTab.CX_ROAD_MAP,
        //OmoikaneTab.CLUSTERS
    ],
    monthdsCoverage: 3,
    indicators: [
        {
            indicator: 'VENTA_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'POSTVENTA_CUSTOMER_JOURNEY',
            keyExtract: [],
            grouped: "question",
            extras: {
                omoikane: true,
                groupNameFormat: "description"
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-venta',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'SENTIMENT',
            key: 'sentiment-postventa',
            keyExtract: [],
            grouped: 'tag',
            extras: {
                omoikane: true,
                criticalMomentInclude: [2]
            }
        },
        {
            indicator: 'CSATVN',
            key: 'satisfaction-venta',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'Experiencia_CSI',
            key: 'satisfaction-postventa',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [2]
            }
        },
        {
            indicator: 'CSATVN',
            key: 'nps-venta',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'recomendacionpv',
            key: 'nps-postventa',
            keyExtract: [],
            extras: {
                omoikane: true,
                criticalMomentInclude: [2]
            }
        }/*,
        {
            indicator: 'SURVEY_RESPONSE_COUNTERS',
            key: 'survey-count-venta',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [1]
            }
        },
        {
            indicator: 'SURVEY_RESPONSE_COUNTERS',
            key: 'survey-count-postventa',
            keyExtract: [],
            grouped: 'mlCluster',
            extras: {
                omoikane: true,
                groupNameFormat: 'description',
                criticalMomentInclude: [2]
            }
        }*/
    ],
    sentiment: {
        indicators: {
            VENTA: 'sentiment-venta',
            POSVENTA: 'sentiment-postventa'
        },
        properties: {
            sentiment: 'sentimentGlobal',
            percentage: 'percentageGlobal',
            sentimentLocal: 'sentimentLocal',
            percentageLocal: 'percentageLocal'
        }
    },
    cxRay: {
        indicators: {
            VENTA: [
                { order: 1, name: 'NPS', key: 'nps-venta', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-venta', widgetType: 'gauge' },
            ],
            POSVENTA: [
                { order: 1, name: 'NPS', key: 'nps-postventa', widgetType: 'pie' },
                { order: 2, name: 'CSAT', key: 'satisfaction-postventa', widgetType: 'gauge' },
            ]
        }
    },
    clusters: {
        VENTA: {
            counters: { indicator: 'survey-count-venta' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'CSATVN',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'CSATVN',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [1]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [1]
                        }
                    }
                ]
            }
        },
        POSVENTA: {
            counters: { indicator: 'survey-count-postventa' },
            detail: {
                indicators: [
                    {
                        id: 'csat',
                        indicator: 'Experiencia_CSI',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'nps',
                        indicator: 'recomendacionpv',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {}
                    },
                    {
                        id: 'best-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'top',
                            criticalMomentInclude: [2]
                        },
                    },
                    {
                        id: 'worst-tags',
                        indicator: 'SENTIMENT',
                        keyExtract: [],
                        grouped: 'mlCluster,tag',
                        extras: {
                            resultType: 'bottom',
                            criticalMomentInclude: [2]
                        },
                    },
                    {
                        id: 'last-comments',
                        indicator: 'COMMENTS',
                        keyExtract: [],
                        //grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [2]
                        }
                    },
                    {
                        id: 'alerts',
                        indicator: 'SURVEY_COUNT',
                        keyExtract: [],
                        grouped: 'mlCluster',
                        extras: {
                            criticalMomentInclude: [2]
                        }
                    },
                    {
                        id: 'alerts-counters',
                        indicator: 'ALERT_COUNTERS',
                        keyExtract: [],
                        grouped: 'mlCluster,alertType',
                        extras: {
                            criticalMomentInclude: [2]
                        }
                    }
                ]
            }
        }
    },
    customerJourney: {
        VENTA: (data: any) => {
            return data['VENTA_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        },
        POSVENTA: (data: any) => {
            return data['POSTVENTA_CUSTOMER_JOURNEY']?.data.map((item: any, idx: number) => ({
                key: idx,
                number: (idx + 1).toString(),
                title: item.group,
                items: [
                    { order: 1, name: '', value: item.value }
                ]
            }))
        }
    }
}
