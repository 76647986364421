import { filterGeos } from '@utils/GeoUtils'


export const widgetsTrend: CommonWidget = {

  /**************************************************************************
   *                                TRENDS
  ***************************************************************************/

  genericTrendByGeo: {
    title: 'FALTA',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'generic-trend-by-geo',
      type: 'trend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      showTrend: false,
      decimals: 1,
      multiIndicator: true,
      indicators: [
        {
          indicator: 'FALTA',
          grouped: 'geoLocation',
          keyExtract: ['value', 'groupName', 'count', 'group'],
          value: 'value',
          singleton: true,
          //limit: 5,
          //sort: { sortBy: 'value', value: 'asc' },
          extras: {},
        }
      ],
    },
  },
  trendByMonth: {
    title: 'Satisfacción general',
    // [Column, Row]
    position: [0, 2],
    size: [1, 2],
    config: {
      id: 'trend-by-month',
      type: 'trend',
      color: '#f38625',
      valueSymbol: '%',
      showHistoric: false,
      showSample: true,
      decimals: 1,
      indicators: [
        {
          indicator: 'satisfaction',
          grouped: 'formattedMonth',
          keyExtract: ['value', 'groupName', 'count'],
          value: 'value',
          singleton: true,
          extras: {
            periods: 6,
          },
        },
      ],
    },
  },
  surveyCountTrendByMonth: {
    subTitle: '# de Encuestas por mes',
    helpLink: '--',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'survey-count-trend-by-month',
      type: 'trend',
      colors: '#a7eb12',
      showHistoric: false,
      percentageScale: false,
      showSample: true,
      backgroundColor: '#5F2B87',
      sampleColor: '#5F2F82',
      fontColor: 'white',
      mapper: (data, extra) => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length

        return data.map((item: any, idx: any) => ({
          ...item,
          id: idx,
          value:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'].toFixed(1)
              : 0,
          trend:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount'] / selectedGeos
              : 0,
          count:
            item.value && item.value['answeredCount']
              ? item.value['answeredCount']
              : 0,
        }))
      },
      indicators: [
        {
          indicator: 'survey-count',
          grouped: 'formattedMonth',
          keyExtract: ['group', 'value', 'groupName', 'count'],
          label: 'group',
          value: 'value',
          dataType: 'list',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {
          },
        },
      ],
    },
  },
  surveyCountTrendByWeek: {
    subTitle: '# de Encuestas por semana',
    position: [0, 0],
    size: [0, 0],
    config: {
      id: 'survey-count-trend-by-week',
      type: 'trend',
      colors: '#a7eb12',
      showHistoric: false,
      percentageScale: false,
      showSample: true,
      backgroundColor: '#5F2B87',
      sampleColor: '#5F2F82',
      fontColor: 'white',
      mapper: (data: any, extra: any) => {
        const selectedGeos = filterGeos(
          extra['geoLocations'],
          extra['geoLocationsInclude']
        ).length
        return data.map((item: any, idx: any) => {
          const group = item.group ?? item['group_0']
          return {
            ...item,
            groupName:
              group !== undefined && group !== null
                ? 'Sem. ' + group.split('-')[1]
                : group, //'Sem. ' + idx,
            value:
              item.value && item.value['answeredCount']
                ? item.value['answeredCount']
                : 0,
            trend:
              item.value && item.value['answeredCount']
                ? item.value['answeredCount'] / selectedGeos
                : 0,
            count:
              item.value && item.value['answeredCount']
                ? item.value['answeredCount']
                : 0,
          }
        })
      },
      indicators: [
        {
          indicator: 'survey-count',
          grouped: 'formattedWeek',
          keyExtract: ['group', 'value', 'groupName', 'count', 'group_0',],
          label: 'group',
          value: 'value',
          dataType: 'list',
          singleton: true,
          sort: { sortBy: 'group', value: 'asc' },
          extras: {},
        },
      ],
    },
  },
}
